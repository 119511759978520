<script setup lang="ts">
import { NotificationAlertProps } from "./NotificationAlert.props"
import { iconsMap } from "./NotificationAlert.iconMapping"

const route = useRoute()

const props = withDefaults(defineProps<NotificationAlertProps>(), {
  notificationAlertTitle: "",
  isWithRepresentativeIcon: false,
  isWithCloseIcon: false,
  isToastNotification: false,
  isSelectable: false,
  isPopUp: false,
  path: "",
  small: false,
  isPersistent: false,
  ctaInine: false
})

const { isOpen: isSearchBarOpened } = useUIState("focus-search")

// Logic to hide the notification
const isHidden = ref(false)

const emit = defineEmits<{ (e: "onClose"): void }>()

const handleClose = () => {
  emit("onClose")
}

if (props.isSelectable || props.isPopUp) {
  isHidden.value = false
}

if (!props.isPersistent) {
  onMounted(() => {
    setTimeout(
      () => {
        emit("onClose")
      },
      props.showingTime ? props.showingTime : 8000
    )
  })
}
</script>

<template>
  <div>
    <AppLink
      class="notification_alert-wrapper"
      :to="path"
      :class="[
        isSelectable ? 'cursor-pointer' : 'cursor-default',
        small || isSearchBarOpened ? 'max-w-[400px]' : 'w-full'
      ]"
    >
      <div
        :class="[`bg-alert-${type}`, { hidden: isHidden }]"
        class="
          flex
          justify-between
          gap-2
          rounded-lg
          border
          px-4
          py-3
          text-black-main
        "
      >
        <div class="flex items-center gap-2">
          <div class="flex h-full">
            <component
              v-if="isWithRepresentativeIcon"
              :is="iconsMap[type]"
              class="h-6 w-6"
            />
          </div>

          <!-- Alert Notification Text -->
          <div class="flex-col">
            <p
              class="dolphin-bold md:pig-bold mb-2 text-left"
              v-if="notificationAlertTitle"
            >
              {{ notificationAlertTitle }}
            </p>

            <p v-if="notificationAlertText" class="beaver text-start">
              <span class="mr-2">
                {{ notificationAlertText }}
              </span>
              <button
                @click="ctaFunction"
                v-if="ctaFunction && ctaInline"
                class="font-bold underline"
              >
                {{ cta ?? "Clicca qui" }}
              </button>
            </p>
            <p v-if="ctaFunction && !ctaInline" class="text-left">
              <button
                @click="ctaFunction"
                class="pt-4 text-negative-100 underline"
              >
                {{ cta ?? "Clicca qui" }}
              </button>
            </p>
          </div>
        </div>

        <UtilsIcon
          name="ChevronRight.svg"
          v-if="isSelectable"
          class="h-6 w-6"
        />

        <UtilsIcon
          name="Close.svg"
          v-else-if="isWithCloseIcon"
          @click="handleClose"
          class="h-6 w-6 flex-shrink-0 cursor-pointer text-black-main"
        />
      </div>
    </AppLink>
  </div>
</template>
